import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Swal from 'sweetalert2'
import * as Yup from "yup";

import { register } from "../slices/auth";
import { clearMessage } from "../slices/message";

const Register = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  
  const { user: currentUser } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    name: "",
    nit: "",
    email: "",
    type: ""
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    nit: Yup.number().required("This field is required!"),
    email: Yup.string().required("This field is required!"),
  });

  const handleRegister = (formValue, {resetForm}) => {
    const { name, nit, email, type } = formValue;
    const alert = document.getElementById('alert');
    setLoading(false);

    dispatch(register({ name, nit, email, type }))
      .unwrap()
      .then((response) => {
        const clientId = (JSON.parse(response)).clientId;
        const clientSecret = (JSON.parse(response)).clientSecret;
        console.log(clientId);
        Swal.fire(`El usuario ha sido registrado`,
          `Username: ${clientId} - Password: ${clientSecret}`,
          'success'
          )        
        alert.style.display = "none";
        resetForm();
      })
      .catch(() => {
        alert.style.display = "block"
        setLoading(false);
      });
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="col-md-12 login-form">
      <div className="card card-container">
        {/* <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        /> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={ handleRegister }
        >
          <Form>
            <div className="form-group">
              <label htmlFor="name">name</label>
              <Field name="name" type="text" className="form-control" />
              <ErrorMessage
                name="name"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="nit">NIT</label>
              <Field name="nit" type="nit" className="form-control" />
              <ErrorMessage
                name="nit"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field name="email" type="email" className="form-control" />
              <ErrorMessage
                name="email"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="type">Tipo</label>
              <Field as="select" className="form-control" name="type" type="type" >
                <option value={""}></option>
                <option value='INTERNAL'>INTERNAL</option>
                <option value='EXTERNAL'>EXTERNAL</option>
              </Field>
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Register</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div id="alert" className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>

    </div>
  );
};

export default Register;
