import axios from "axios";
import { setupInterceptorsTo } from "./interceptors/interceptors";

const api = setupInterceptorsTo(
  axios.create({
    baseURL: `${process.env.REACT_APP_TRACKING_SECURITY_API}/shipping-carrier`,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

const findAllByExternalProcess = (name, nit, email) => {
  return api.get("/external");
};

const shippingCarriersService = {
  findAllByExternalProcess,
};

export default shippingCarriersService;
