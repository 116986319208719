import axios from "axios";
import { formatParams } from "../common/SearchParams";
import { setupInterceptorsTo } from "./interceptors/interceptors";

const api = setupInterceptorsTo(
  axios.create({
    baseURL: `${process.env.REACT_APP_TRACKING_ERROR_LOG_API}/archive`,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

const getLogBoard = (
  page = 1,
  perPage = 10,
  initialDate = null,
  finalDate = null,
  search = null,
  status = null
) => {
  const objString = formatParams({
    page,
    limit: perPage,
    initialDate,
    finalDate,
    search,
    status,
  });
  return api.get(`${objString}`);
};

const getLogDetailBoard = (id, page = 1, perPage = 10) => {
  const objString = formatParams({
    page,
    limit: perPage,
  });
  return api.get(`/${id}/detail${objString}`);
};

const fileLogService = {
  getLogBoard,
  getLogDetailBoard,
};

export default fileLogService;
