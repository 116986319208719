import secureLocalStorage from "react-secure-storage";

export default function authHeader() {
  const user = JSON.parse(secureLocalStorage.getItem("user"));

  if (user && user.access_token) {
    // For Spring Boot back-end
    return `Bearer ${user.access_token}`;
  } else {
    return {};
  }
}
