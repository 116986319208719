import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useParams, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import fileLogService from "../services/file-log.service";

const paginationComponentOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
};

const DetailFile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  let { id: idFile } = useParams();

  const columns = [
    {
      name: "Linea",
      selector: (row) => row.line,
    },
    {
      name: "Entity Id",
      selector: (row) => row.entity_id,
    },
    {
      name: "Descripcion",
      selector: (row) => row.description,
    },
  ];

  const fetchLogs = async (id, page = 1, perPage = 10) => {
    setLoading(true);

    const response = await fileLogService.getLogDetailBoard(id, page, perPage);

    const [data, total] = response.data.archiveDetail;
    setData(data);
    setTotalRows(total);
    setLoading(false);
  };

  const handlePageChange = (page) => {
    setPage(page);
    fetchLogs(idFile, page, perPage);
  };

  const handlePerRowsChange = async (newPerPage) => {
    if (!data.length) return;
    fetchLogs(idFile, 1, newPerPage);
    setPerPage(newPerPage);
    setPage(1);
  };

  useEffect(() => {
    fetchLogs(idFile);
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Detalle archivo</h3>
      </header>

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationComponentOptions={paginationComponentOptions}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10]}
      />
    </div>
  );
};

export default DetailFile;
