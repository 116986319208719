import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import fileLogService from "../services/file-log.service";
import shippingCarriersService from "../services/shipping-carriers.service";

const paginationComponentOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
};

const BoardFile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [statusList] = useState([
    {
      name: "Error",
    },
    {
      name: "Exitoso",
    },
    {
      name: "Procesando",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [startDate, setStartDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [nit, setNit] = useState(null);
  const [status, setStatus] = useState(null);
  const currentDay = moment().format("YYYY-MM-DD");

  const columns = [
    {
      name: "Nombre archivo",
      selector: (row) => row.fileName,
    },
    {
      name: "Nit",
      selector: (row) => row.nit,
    },
    {
      name: "Estado",
      selector: (row) => row.status,
    },
    {
      cell: (row) => (
        <button
          className="btn btn-primary btn-sm"
          onClick={() => getDetail(row.id)}
          id={row.id}
        >
          Detalle
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleData = (page, perPage, filter) => {
    const existFilter = startDate || finalDate || nit || status;
    return filter && existFilter
      ? fetchLogs(page, perPage, startDate, finalDate, nit, status)
      : fetchLogs(page, perPage);
  };

  const fetchLogs = async (
    page = 1,
    perPage = 10,
    startDate = null,
    finalDate = null,
    nit = null,
    status = null
  ) => {
    setLoading(true);

    const response = await fileLogService.getLogBoard(
      page,
      perPage,
      startDate,
      finalDate,
      nit,
      status
    );

    const [data, total] = response.data;
    setData(data);
    setTotalRows(total);
    setLoading(false);
  };

  const fetchCarriers = async () => {
    const response = await shippingCarriersService.findAllByExternalProcess();
    setCarriers(response.data);
  };

  const handlePageChange = (page) => {
    setPage(page);
    handleData(page, perPage, true);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    await setPerPage(newPerPage);
    console.log("perPage :>> ", perPage);
    fetchLogs(page, newPerPage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleData(page, perPage, true);
  };

  const reset = () => {
    setStartDate("");
    setFinalDate("");
    setNit(null);
    setStatus(null);

    handleData(page, perPage, false);
  };

  const getDetail = async (id) => {
    navigate(`/file/${id}/detail`, {
      id,
    });
  };

  const dateMoreThan = (date, comparisonDate) => {
    return moment(date).isAfter(comparisonDate);
  };

  const dateLessThan = (date, comparisonDate) => {
    return moment(date).isBefore(comparisonDate);
  };

  const onStartDateChange = (event) => {
    let inputStart = event.target.value;

    if (
      dateMoreThan(inputStart, currentDay) ||
      dateMoreThan(inputStart, finalDate)
    ) {
      setStartDate("");
      return;
    }
    setStartDate(inputStart);
  };

  const onFinalDateChange = (event) => {
    let inputFinal = event.target.value;

    if (
      dateMoreThan(inputFinal, currentDay) ||
      dateLessThan(inputFinal, startDate)
    ) {
      setFinalDate("");
      return;
    }
    setFinalDate(inputFinal);
  };

  useEffect(() => {
    fetchLogs();
    fetchCarriers();
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Log archivos</h3>
      </header>

      <form className="mb-1" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-auto col-sm-12 col-md-3">
            <label htmlFor="inputPassword2" className="mt-0">
              Fecha inicial
            </label>
            <input
              type="date"
              className={classNames({
                "form-control": true,
                "is-invalid":
                  dateMoreThan(startDate, currentDay) ||
                  dateMoreThan(startDate, finalDate),
              })}
              placeholder="YYYY-MM-DD"
              max={currentDay}
              value={startDate}
              onChange={onStartDateChange}
            />
          </div>
          <div className="col-auto col-sm-12 col-md-3">
            <label htmlFor="inputPassword2" className="mt-0">
              Fecha final
            </label>
            <input
              type="date"
              className={classNames({
                "form-control": true,
                "is-invalid":
                  dateMoreThan(finalDate, currentDay) ||
                  dateLessThan(finalDate, startDate),
              })}
              placeholder="YYYY-MM-DD"
              min={startDate}
              max={currentDay}
              value={finalDate}
              onChange={onFinalDateChange}
            />
          </div>
          <div className="col-auto col-sm-12 col-md-2">
            <label htmlFor="exampleFormControlSelect1" className="mt-0">
              Transportadora:
            </label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={(e) => setNit(e.target.value)}
            >
              <option value={""}>-</option>
              {carriers &&
                carriers.map(({ name, nit }, key) => (
                  <option key={key} value={nit}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-auto col-sm-12 col-md-2">
            <label htmlFor="exampleFormControlSelect1" className="mt-0">
              Estado:
            </label>
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value={""}>-</option>
              {statusList &&
                statusList.map(({ name }, key) => (
                  <option key={key} value={name}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-auto col-sm-12 col-md-2">
            <button
              type="reset"
              onClick={reset}
              className="btn btn-light btn-block btn-sm"
            >
              Limpiar
            </button>
            <button type="submit" className="btn btn-primary btn-block btn-sm">
              Filtrar
            </button>
          </div>
        </div>
      </form>

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10]}
        paginationComponentOptions={paginationComponentOptions}
      />
    </div>
  );
};

export default BoardFile;
