import axios from "axios";
import { setupInterceptorsTo } from "./interceptors/interceptors";

const api = setupInterceptorsTo(
  axios.create({
    baseURL: `${process.env.REACT_APP_TRACKING_FILE_STATUS_API}/archive`,
    headers: {
      "Content-Type": "application/json",
    },
  })
);

const uploadFile = (formData) => {
  try {
    return api.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    throw (
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    );
  }
};

const fileService = {
  uploadFile,
};

export default fileService;
