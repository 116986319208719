import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import store from "../../store";
import { logout } from "../../slices/auth";

const API_URL = process.env.REACT_APP_TRACKING_SECURITY_API;

const onRequest = async (config) => {
  const user = JSON.parse(secureLocalStorage.getItem("user"));

  const accessToken = jwt_decode(user.access_token);
  const isExpired = dayjs.unix(accessToken.exp).diff(dayjs()) < 1;

  if (!isExpired) {
    config.headers["Authorization"] = `Bearer ${user.access_token}`;
    return config;
  }

  await axios
    .post(
      `${API_URL}/auth/refresh`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user.refresh_token}`,
        },
      }
    )
    .then((response) => {
      secureLocalStorage.setItem("user", JSON.stringify(response.data));
      config.headers.common.Authorization = `Bearer ${response.data.access_token}`;
    })
    .catch(() => {
      delete config.headers.common["Authorization"];
      store.dispatch(logout());
    });

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
