export const formatParams = (data) => {
  return (
    "?" +
    Object.keys(data)
      .filter((x) => data[x])
      .map((key) => {
        return `${key}=${encodeURIComponent(data[key])}`;
      })
      .join("&")
  );
};

//export default { UrlSearchParams };