import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
//import { toast, ToastContainer } from "react-toastify";
import Swal from 'sweetalert2'

import fileService from "../services/file.service";
import shippingCarriersService from "../services/shipping-carriers.service";

// Allowed extensions for input file
const allowedExtensions = ["csv"];

const UploadFile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [file, setFile] = useState(null);
  const [carriers, setCarriers] = useState([]);
  const [nit, setNit] = useState(null);

  const onFileChange = (event) => {
    let inputFile = event.target.files[0];
    const fileExtension = inputFile?.type.split("/")[1];
    if (!allowedExtensions.includes(fileExtension)) {
      setFile(null);
      return;
    }
    setFile(inputFile);
  };

  const onFileUpload = async (e) => {
    e.preventDefault();
    if (!file || !nit) return;

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("nit", nit);
    formData.append("file", file, file.name);

    // Request made to the backend api
    // Send formData object
    await fileService
      .uploadFile(formData)
      .then(() => {
        Swal.fire('El archivo cargó exitosamente',
          `${nit}`,
          'success'
          ) 
        setFile(null);
        setNit(null);
      })
      .catch((err) => {/* 
        toast.error(`${err.message ? err.message : err}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }); */
        Swal.fire(`${err.message ? err.message : err}`, {
          icon: 'error',
          title: err.message,
          text: 'Something went wrong!',
        });
      });
  };

  const fetchCarriers = async () => {
    const response = await shippingCarriersService.findAllByExternalProcess();
    setCarriers(response.data);
  };

  useEffect(() => {
    fetchCarriers();
  }, []);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>Subir archivo</h3>
      </header>

      <form onSubmit={onFileUpload}>
        <div className="form-group">
          <div className="custom-file">
            <input
              type="file"
              className={classNames({
                "custom-file-input": true,
                "is-valid": file,
                "is-invalid": !file,
              })}
              required
              onChange={onFileChange}
              accept={".csv"}
            />
            <label className="custom-file-label">
              {!file ? "Elija el archivo..." : file.name}
            </label>
            <div className="invalid-feedback pt-2">
              Seleccione un archivo válido.
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Transportadora</label>
          <select
            className={classNames({
              "form-control": true,
              "is-valid": nit,
              "is-invalid": !nit,
            })}
            onChange={(e) => setNit(e.target.value)}
          >
            <option value={""}>-</option>
            {carriers &&
              carriers.map(({ name, nit }, key) => (
                <option key={key} value={nit}>
                  {name}
                </option>
              ))}
          </select>
          <div className="invalid-feedback">
            Seleccione un transportador válido.
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          Subir
        </button>
      </form>

      {/* <ToastContainer /> */}
    </div>
  );
};

export default UploadFile;
