import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import authHeader from "./auth-header";


const API_URL = `${process.env.REACT_APP_TRACKING_SECURITY_API}`;

const baseUrl = `${API_URL}/shipping-carrier`
// const baseUrl = "https://web-dev-az-lkmtracking-security.azurewebsites.net/shipping-carrier"
// console.log(baseUrl);
// console.log(JSON.stringify(authHeader()));

const register = (name, nit, email, type) => {
  const data = { name, nit, email, type };

  let config = {
    method: 'post',
    url: baseUrl,
    headers: {
      'Authorization': authHeader(),
      'Content-Type': 'application/json'
    },
    data: data
  };
  
  return axios.request(config)    
  .then((response) => {
    return JSON.stringify(response.data);
  })
  .catch(error => {
    console.error(error);
    throw error;
  });
};

const login = async (clientId, clientSecret) => {
  return await axios
    .post(API_URL + "/auth/login", {
      clientId,
      clientSecret,
      type: "INTERNAL"
    })
    .then((response) => {
      if (response.data.access_token) {
        secureLocalStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  secureLocalStorage.clear();
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
